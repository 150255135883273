<!--
* @Author: DY
* @Date: 2021年1月7日18:48:13
* @Description: '新增市场价'
-->
<template>
    <div class="newMarketPrice">
        <div class="newMarketPriceContent">
            <div class="formList">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="编码">
                        <el-input v-model="form.info_price_number" placeholder="保存自动生成" disabled></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="产品名称">
                        <el-input v-model="form.info_price_name" placeholder="请填写产品名称"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formList">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="名称">
                        <el-input v-model="form.specification_mode_name" placeholder="名称自动生成" disabled></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="产品标号">
                        <el-select
                            v-model="form.product_mark_code"
                            placeholder="请选择标号"
                            @change="confirmSelection(gradeData,'product_mark_code','product_mark_num')"
                        >
                            <template v-for="(item, index) in gradeData">
                                <el-option :key="index" :label="item.name" :value="item.code"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formList">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="附加型号">
                        <el-input v-model="form.additional_model" placeholder="请选择附加型号" @focus="selectAdditionalModels()"></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="浇筑方式">
                        <el-select
                            v-model="form.pour_mode_code"
                            placeholder="请选择浇筑方式"
                            @change="confirmSelection(pouringMethodData,'pour_mode_code','pour_mode')"
                        >
                            <template v-for="(item, index) in pouringMethodData">
                                <el-option :key="index" :label="item.name" :value="item.code"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formList">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="粗骨料">
                        <el-select
                            v-model="form.coarse_aggregate_code"
                            placeholder="请选择粗骨料"
                            @change="confirmSelection(coarseAggregateData,'coarse_aggregate_code','coarse_aggregate')"
                        >
                            <template v-for="(item, index) in coarseAggregateData">
                                <el-option :key="index" :label="item.name" :value="item.code"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="细骨料">
                        <el-select
                            v-model="form.fine_aggregate_code"
                            placeholder="请选择细骨料"
                            @change="confirmSelection(fineAggregateData,'fine_aggregate_code','fine_aggregate')"
                        >
                            <template v-for="(item, index) in fineAggregateData">
                                <el-option :key="index" :label="item.name" :value="item.code"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="formList">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="计量单位">
                        <el-input
                            v-model="form.measuring_unit"
                            placeholder="请填写计量单位"
                            disabled
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <footer>
            <el-button type="primary" @click="confirmAdd">
                确认
            </el-button>
            <el-button @click="close">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'new-market-price',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            form: {
                // 信息价地区编码
                info_price_regional_number: '',
                // 信息价编码
                info_price_number: '',
                // 信息价名称
                info_price_name: '',
                // 规格型号名称
                specification_mode_name: '',
                // 产品标号编码
                product_mark_code: '',
                // 产品标号
                product_mark_num: '',
                // 附加型号编码
                additional_model_code: '',
                // 附加型号
                additional_model: '',
                // 粗骨料编码
                coarse_aggregate_code: '',
                // 粗骨料
                coarse_aggregate: '',
                // 细骨料编码
                fine_aggregate_code: '',
                // 细骨料
                fine_aggregate: '',
                // 编码组合 产品标号编码+附加型号编码+粗骨料编码+细骨料编码+浇筑方式编码
                compose_code: '',
                // 浇筑方式
                pour_mode: '',
                // 浇筑方式编码
                pour_mode_code: '',
                // 计量单位
                measuring_unit: '',
            },
            // 标号下拉数据
            gradeData: [],
            // 附加型号下拉数据
            additionalModelsData: [],
            // 浇筑方式下拉数据
            pouringMethodData: [{
                name: '泵送',
                code: '1',
            }, {
                name: '非泵送',
                code: '0',
            }],
            // 粗骨料下拉数据
            coarseAggregateData: [],
            // 细骨料下拉数据
            fineAggregateData: [],
        };
    },
    watch: {},
    computed: {},
    created() {
        // 信息价地区编码赋值
        this.form.info_price_regional_number = this.extr.regionalNumber;
        // 计量单位
        this.form.measuring_unit = 'm³';
        // 获取下拉框数据列表
        this.getCategory();
    },
    mounted() {},
    methods: {
        // 获取下拉框数据列表
        async getCategory() {
            // 标号下拉数据
            const gradeData = await this.getGradeApi();
            if (gradeData) {
                this.gradeData = gradeData;
            }
            // 粗骨料下拉数据
            const coarseAggregateData = await this.getMaterielCategoryApi_cgl('204');
            if (coarseAggregateData) {
                coarseAggregateData.forEach(item => {
                    if (item.code === '204-HGY' || item.code === '204-SS0' || item.code === '204-XWY' ) {
                        item.name = item.name + '石子';
                    }
                });
                this.coarseAggregateData = coarseAggregateData;
            }
            // 细骨料下拉数据
            const fineAggregateData = await this.getMaterielCategoryApi_xgl('F3-02');
            if (fineAggregateData) {
                this.fineAggregateData = fineAggregateData;
            }
        },
        // 拼接产品名称
        spliceProductName() {
            let nweName = '';
            let nweCode = '';
            if (this.form.product_mark_code !== '') {
                nweName += this.form.product_mark_num + ' ';
                nweCode += this.form.product_mark_code + '|';
            }
            if (this.form.additional_model_code !== '') {
                nweName += this.form.additional_model + ' ';
                nweCode += this.form.additional_model_code + '|';
            }
            if (this.form.coarse_aggregate_code !== '') {
                nweName += this.form.coarse_aggregate + ' ';
                nweCode += this.form.coarse_aggregate_code + '|';
            }
            if (this.form.fine_aggregate_code !== '') {
                nweName += this.form.fine_aggregate + ' ';
                nweCode += this.form.fine_aggregate_code + '|';
            }
            if (this.form.pour_mode_code !== '') {
                nweName += '(' + this.form.pour_mode + ') ';
                nweCode += this.form.pour_mode_code + '|';
            }
            if (nweCode !== '') {
                nweCode = nweCode.substr(0, nweCode.length - 1);
            }
            this.form.specification_mode_name = nweName.trim();
            this.form.compose_code = nweCode;
        },
        // 下拉框确认选择
        confirmSelection(datas, code, name) {
            const row = datas.filter(item => item.code === this.form[code]);
            this.form[name] = row[0].name;
            this.spliceProductName();
        },

        // 确认添加
        confirmAdd() {
            this.$axios
                .post('/interfaceApi/sale/info_price/add_warehouse', this.form)
                .then(res => {
                    this.form = res;
                    this.$message({
                        message: '添加成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr.selectCallBack();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        close() {
            this.$parent.hide();
        },

        // 获取产品标号数据
        getGradeApi() {
            return this.$axios.$get('/interfaceApi/sale/info_price/get_product_mark_list', { defEx: true });
        },
        // 获取字典数据
        getCategoryApi(parentCode) {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist_recursion?parent_code=' + parentCode, { defEx: true });
        },
        // 获取物料字典数据_细骨料
        getMaterielCategoryApi_xgl(parentCode) {
            return this.$axios.$get('/interfaceApi/baseinfo/businessgroup_config/getlist?parent_code=' + parentCode, { defEx: true });
        },
        // 获取细骨料字典数据_粗骨料
        getMaterielCategoryApi_cgl(parentCode) {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + parentCode, { defEx: true });
        },
        // 选择附加型号
        selectAdditionalModels() {
            // this.$toast(
            //     {
            //         title: true,
            //         text: '选择附加型号',
            //         type: 'eject',
            //         t_url: 'tableList/index',
            //         extr: {
            //             code: { TableCode: 'xxj-fjxh' },
            //             sureCallback: this.additionalCallback,
            //         },
            //     }
            // );
            this.$toast(
                {
                    title: true,
                    text: '选择附加型号',
                    type: 'eject',
                    width: '14.3rem',
                    height: '90%',
                    t_url: 'salesBusiness/selectModel/index',
                    extr: {
                        sureCallback: this.additionalCallback,
                    },
                }
            );
        },
        // 选取附加型号（完成赋值）- 组合选择
        additionalCallback(data) {
            window.hideToast();
            if (data) {
                let smallclass_code = '';
                let smallclass_name = '';
                data.forEach((item, index) => {
                    if (index === data.length - 1) {
                        smallclass_code += item.smallclass_code;
                        smallclass_name += item.smallclass_name;
                    } else {
                        smallclass_code += item.smallclass_code + ',';
                        smallclass_name += item.smallclass_name + '+';
                    }
                });
                this.form.additional_model_code = smallclass_code;
                this.form.additional_model = smallclass_name;
                this.spliceProductName();
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.newMarketPrice
    height 100%
    padding 0.2rem
    .newMarketPriceContent
        height calc(100% - 0.8rem)
        .formList
            display flex
            .el-form
                width 50%
                .el-select
                    width 100%
    footer
        height 0.4rem
        margin-top 0.2rem
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
</style>
